<script setup>
import { inject } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import NewFormModal from '~/forms/components/new-form/new-form-modal.vue';
import { useFormPermissions } from '~/forms/composables/form-permissions.composable';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormsStore } from '~/forms/store/forms.store';

const props = defineProps({
  forms_store: { type: Object },
});
const emits = defineEmits(['form-created']);
const common_store = useCommonStore();
const { syncAndGetButtonState } = useFormPermissions();
const form_template_detail_store = useFormTemplateDetailStore();

const $toast = inject('$toast');
const $t = inject('$t');
const route = useRoute();

const forms_store = props.forms_store || useFormsStore();
const form_template_detail = computed(() => form_template_detail_store?.form_template_detail || forms_store?.templates?.[0] || {});

// New form button state and tooltip
function getTemplateAssetName(form_template_detail) {
  return common_store.assets_map?.[form_template_detail?.target_element?.asset]?.name;
}
function getTemplateButtonState() {
  const template_asset_name = getTemplateAssetName(form_template_detail.value);
  if (!form_template_detail?.value?.can_create_forms)
    return { state: 'hidden', tooltip: '' };
  else if (form_template_detail?.value?.target_element.asset && !route.params.asset_id){
    const template_asset_name_string = template_asset_name ? `, ${template_asset_name}` : '';
    return { state: 'disabled', tooltip: `Not allowed to create forms at organization level for asset templates${template_asset_name_string}` };
  }
  else
    return { state: 'enabled', tooltip: '' };
}
const button_state_in_template = computed(getTemplateButtonState);
const button_state = route.params?.template_uid ? button_state_in_template : syncAndGetButtonState();
// ----------------------------------------------------------------

const auth_store = useAuthStore();

function getTargetElement() {
  if (route.params.asset_id)
    return common_store.active_asset.element;
  return auth_store.current_organization.element;
}

const { open: openFormModal, close: closeFormModal } = useModal({
  component: NewFormModal,
  attrs: {
    onClose() {
      closeFormModal();
    },
    async on_submit(data) {
      data.forms.add[0].target_element = getTargetElement();
      await forms_store?.create_form({
        body: data,
      });
      emits('form-created', { template: data.forms.add[0].template });
      closeFormModal();
    },
  },
});
function on_click() {
  $toast({ text: $t('Template Not Yet Published To Create New Form!'), type: 'info', timeout: 4000 });
}
</script>

<template>
  <div v-if="button_state.state !== 'hidden'" v-tippy="button_state.tooltip" class="flex cursor-pointer items-center rounded  hover:bg-gray-50">
    <hawkButton :disabled="button_state.state === 'disabled'" color="primary" @click="form_template_detail?.status === 'draft' ? on_click() : openFormModal()">
      <icon-hawk-plus class="text-white" /> <span>
        <HawkText v-if="form_template_detail?.singular_name" :content="`${$t('New')} ${form_template_detail.singular_name}`" :length="50" />
        <span v-else>
          {{ $t('New Form') }}
        </span>
      </span>
    </hawkButton>
  </div>
</template>
