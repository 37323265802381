import { defineAsyncComponent } from 'vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';

// Template settings pages
import FormsTemplateSettings from '~/forms/pages/forms-template-settings.vue';

// Form pages
import Forms from '~/forms/pages/forms.vue';

const FormsTemplateSettingsGeneral = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-template-settings-general.vue'),
  loadingComponent: HawkLoader,
});
const FormsTemplateSettingsMail = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-template-settings-mail.vue'),
  loadingComponent: HawkLoader,
});
const FormsTemplateSettingsQuickAccess = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-template-settings-quick-access.vue'),
  loadingComponent: HawkLoader,
});
const FormsTemplateSettingsSharing = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-template-settings-sharing.vue'),
  loadingComponent: HawkLoader,
});

const FormsSubmissions = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-submissions.vue'),
  loadingComponent: HawkLoader,
});
const FormsTemplateFormsList = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-template-forms-list.vue'),
  loadingComponent: HawkLoader,
});
const FormsTemplates = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-templates.vue'),
  loadingComponent: HawkLoader,
});

const FormsOverview = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-overview.vue'),
  loadingComponent: HawkLoader,
});
const FormsRequests = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-requests.vue'),
  loadingComponent: HawkLoader,
});

const FormsArchived = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-archived.vue'),
  loadingComponent: HawkLoader,
});
const FormsOverviewCalendarView = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-overview-calendar-view.vue'),
  loadingComponent: HawkLoader,
});
const FormsOverviewListView = defineAsyncComponent({
  loader: () => import('~/forms/pages/forms-overview-list-view.vue'),
  loadingComponent: HawkLoader,
});
const FormsTemplateWorkflowBuilder = defineAsyncComponent({
  loader: () => import('~/forms/pages/form-template-workflow-builder.vue'),
  loadingComponent: HawkLoader,
});

const FormsTemplateBuilder = defineAsyncComponent({
  loader: () => import('~/forms/pages/form-template-builder.vue'),
  loadingComponent: HawkLoader,
});

const FormApproval = defineAsyncComponent({
  loader: () => import('~/forms/pages/form-approval.vue'),
  loadingComponent: HawkLoader,
});

const routes = [
  {
    path: '/:asset_id?/forms',
    component: Forms,
    name: 'forms',
    redirect: { name: 'forms-overview' },
    meta: {},
    children: [
      {
        path: 'overview',
        component: FormsOverview,
        name: 'forms-overview',
        redirect: { name: 'forms-overview-list-view' },
        meta: {
          module: 'forms',
        },
        children: [
          {
            path: 'list',
            component: FormsOverviewListView,
            name: 'forms-overview-list-view',
            meta: {
              view_name: 'Forms overview',
            },
          },
          {
            path: 'calendar',
            component: FormsOverviewCalendarView,
            name: 'forms-overview-calendar-view',
            meta: {
              parent: 'forms-overview-list-view',
            },
          },
        ],
      },
      {
        path: 'templates',
        component: FormsTemplates,
        name: 'forms-templates',
        meta: {
          module: 'forms',
        },
      },
      {
        path: 'submissions',
        component: FormsSubmissions,
        name: 'forms-submission-overview',
      },
      {
        path: 'requests',
        component: FormsRequests,
        name: 'forms-requests',
        meta: {
          module: 'forms',
        },
      },
      {
        path: 'archived',
        component: FormsArchived,
        name: 'forms-archived',
        meta: {
          module: 'forms',
        },
      },
    ],
  },
  {
    path: '/:asset_id?/forms/template/:template_uid/settings',
    meta: {
      module: 'forms',
    },
    component: FormsTemplateSettings,
    name: 'forms-template-settings',
    redirect: { name: 'forms-template-settings-general' },
    children: [{
      path: 'general',
      component: FormsTemplateSettingsGeneral,
      name: 'forms-template-settings-general',
    }, {
      path: 'mail',
      component: FormsTemplateSettingsMail,
      name: 'forms-template-settings-mail',
    }, {
      path: 'quick-access',
      component: FormsTemplateSettingsQuickAccess,
      name: 'forms-template-settings-quick-access',
    }, {
      path: 'sharing',
      component: FormsTemplateSettingsSharing,
      name: 'forms-template-settings-sharing',
    }],
  },
  {
    path: '/:asset_id?/forms/template/:template_uid/list',
    component: FormsTemplateFormsList,
    name: 'forms-template-forms-list',
    meta: {
      module: 'forms',
    },
  },
  {
    path: '/:asset_id?/forms/template/:template_uid/template-builder',
    component: FormsTemplateBuilder,
    name: 'forms-template-builder',
    meta: {
      module: 'forms',
    },
  },
  {
    path: '/:asset_id?/forms/template/:template_uid/workflow-builder',
    component: FormsTemplateWorkflowBuilder,
    name: 'forms-template-workflow-builder',
    meta: {
      module: 'forms',
    },
  },
  {
    path: '/:asset_id?/approvals/:approval_id',
    name: 'form-approval',
    component: FormApproval,
    meta: {
      module: 'forms',
    },
  },
];
export default routes;
