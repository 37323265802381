<!-- eslint-disable vue/prop-name-casing -->
<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    default_index: {
      type: Number,
      required: false,
      default: 0,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      selected: this.options?.[this.default_index],
    };
  },
  watch: {
    selected: {
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<template>
  <Listbox v-model="selected" as="div">
    <div class="relative font-semibold">
      <ListboxButton
        class="cursor-pointer relative w-full rounded-lg bg-white py-2 pl-3 pr-8 text-left focus:outline-none sm:text-sm"
        :class="{ 'shadow-sm border border-gray-300 focus:border-gray-500 focus:ring-1 focus:ring-gray-500': bordered }"
      >
        <span class="block truncate">{{ selected?.label ?? $t('None') }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <IconHawkChevronDown class="ml-auto text-gray-400 min-w-5" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="min-w-max ring-current/5 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-gray-200 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="option in options"
            :key="option.id"
            v-slot="{ active, selection }"
            as="template"
            :value="option"
          >
            <li
              class="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900"
              :class="{ 'bg-gray-100': active }"
            >
              <span class="block truncate" :class="[selection ? 'font-semibold' : 'font-normal']">
                {{ option.label }}
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
