<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { computed } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { $date_relative } from '~/common/utils/date.util';

import CommentBlock from '~/dms/components/documents/comments/document-comment.vue';

const props = defineProps({
  chat_store: {
    type: Object,
    required: true,
  },
  comment: {
    type: Object,
    required: true,
  },
  is_reply: {
    type: Boolean,
    default: false,
  },
  item_uid: {
    type: String,
    required: true,
  },
  asset_id: {
    type: String,
    default: '',
  },
  module: {
    type: String,
    default: 'tasks',
  },
  last_viewed_at: {
    type: Date,
  },
  disabled_controls: {
    type: Array,
    default: () => [],
  },
  validate_comment: {
    type: Function,
  },
});

const chat_store = props.chat_store;
const { logged_in_user_details } = useAuthStore();

const logged_in_user_id = logged_in_user_details?.user_id;
const can_reply = ref(false);
const replies = ref(null);
const show_replies = ref(true);

const comment_attachments = computed(() => {
  return props.comment?.attachment_uids || [];
});

const liked_by_me = computed(() => {
  return props.comment?.own_reactions?.[0]?.type === 'like';
});

const disabled_controls = computed(() => {
  let disabled_control_list = [...props.disabled_controls];
  if (props.is_reply)
    disabled_control_list = disabled_control_list.concat(['pin', 'reply']);
  if (logged_in_user_id !== props.comment?.user?.id)
    disabled_control_list.push('edit');
  return disabled_control_list;
});

const comment_input_config = computed(() => {
  return {
    disabled_controls: disabled_controls.value,
    liked_by_me: liked_by_me.value,
    close_on_disable: true,
    type: 'static',
    comment_text: props.comment.text,
    reaction_count: props.comment?.reaction_counts?.like,
  };
});

watch(() => props.comment, async (new_comment) => {
  if (new_comment.reply_count)
    await getReplies();
}, { immediate: true });

async function getReplies() {
  if (props.comment?.id && props.comment?.reply_count) {
    const messages = await chat_store.chat_channel.getReplies(props.comment.id);
    replies.value = messages.messages || [];
  }
}

async function scrollToReply() {
  const element = document.getElementById(`comment-block`);
  element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
}

function toggleReplyInput() {
  can_reply.value = true;
  show_replies.value = true;
  // Bring the input in view if it's not already
  const scroll = () => {
    const block = document.getElementById(`comment-block`);
    const targetDiv = document.getElementById(`${props.comment.id}-input`);
    const targetRect = targetDiv.getBoundingClientRect();
    const location_offset = 195;
    const scrollPosition = block.scrollTop + targetRect.bottom - window.innerHeight + location_offset;
    block.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  };
  setTimeout(() => scroll(), 100);
}

async function deleteComment() {
  await chat_store.chat.client.deleteMessage(props.comment.id);
}

async function deleteAttachment(file) {
  if (props.comment.text) {
    const attachment_uids = props.comment.attachment_uids.filter(uid => uid !== file.uid);

    const payload = {
      ...props.comment,
      attachment_uids,
      service: 'documents',
    };

    if (attachment_uids.length >= props.comment.attachment_uids.length) {
      const attachment_upload = props.comment.attachment_upload.filter(obj => obj.uid !== file.uid);
      payload.attachment_upload = attachment_upload;
    }

    await chat_store.updateMessage(payload);
  }
  else {
    await deleteComment();
  }
}

function closeReply() {
  can_reply.value = false;
}

defineExpose({ closeReply });
</script>

<script>
export default {
  name: 'CommentBlock',
};
</script>

<template>
  <div :id="comment.id">
    <div
      class="flex items-start hover:bg-gray-50 rounded group px-3 py-2"
      :class="{
        'opacity-50': props.comment.type === 'deleted',
      }"
    >
      <HawkMembers size="xs" :members="props.comment?.user?.id" class="mr-2" />
      <div class="w-full">
        <div class="flex items-center mb-0.5 flex-wrap" :class="{ 'w-full justify-between': !comment_attachments?.length }">
          <div class="mr-2 text-center flex items-center">
            <HawkMembers :members="props.comment.user.id" :has_avatar="false" type="label" class="text-sm whitespace-nowrap" />
            <icon-hawk-pinned v-if="props.comment.pinned" class="h-3.5 w-3.5 mx-2 text-warning-400" />
            <span v-if="last_viewed_at && last_viewed_at < props.comment.created_at" class="bg-blue-500 w-2 h-2 ml-2 rounded-full" />
          </div>
          <p
            v-if="props.comment.created_at" class="text-xs font-regular text-gray-600 text-center whitespace-nowrap"
            :class="{
              'group-hover:hidden': props.comment.type !== 'deleted',
            }"
          >
            {{ $date_relative(props.comment.created_at) }}
          </p>
        </div>
        <div v-if="props.comment?.document_annotation_uid" class="flex text-xs text-gray-500 items-center">
          {{ $t('Added on annotation') }} <icon-hawk-hexagon-one class="text-orange-500 w-4 h-4 ml-1" />
        </div>
        <p v-if="props.comment.type === 'deleted'" class="text-sm font-regular text-gray-700 italic">
          {{ is_reply ? $t('This reply was deleted') : $t('This message was deleted') }}
        </p>
        <div v-else class="-mx-2">
          <ChatCommentInput
            v-if="props.comment.text"
            :key="props.comment.text"
            :module="props.module"
            :chat_store="chat_store"
            :user="logged_in_user_id"
            :asset_id="props.asset_id"
            :comment="comment"
            :attachment_config="{ meta: { service: props.module, id: props.item_uid } }"
            :comment_input_config="comment_input_config"
            :validate_comment="props.validate_comment"
            @reply="toggleReplyInput"
            @delete="deleteComment"
          >
            <template #right_footer>
              <div v-if="replies && replies.length" class="text-sm font-semibold text-blue-700 cursor-pointer" @click="show_replies = !show_replies">
                {{ show_replies ? $t('Hide replies') : $t('Show replies') }} ({{ replies?.length || 0 }})
              </div>
            </template>
          </ChatCommentInput>
          <div v-if="comment_attachments?.length" class="w-fit mb-4 mt-2">
            <HawkAttachmentsGrid
              :items="comment_attachments"
              :can_delete="logged_in_user_id === comment.user.id"
              @delete="deleteAttachment"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="show_replies && replies?.length" class="ml-11">
      <div v-for="(reply, index) in replies" :key="index">
        <CommentBlock
          :asset_id="props.asset_id"
          :chat_store="chat_store"
          :comment="reply"
          :item_uid="item_uid"
          :module="props.module"
          :disabled_controls="disabled_controls"
          :validate_comment="props.validate_comment"
          :is_reply="true"
        />
      </div>
    </div>
    <div v-if="can_reply" :id="`${comment.id}-input`" class="mt-2 mb-4 flex ml-11">
      <HawkMembers size="xs" :members="logged_in_user_id" class="mr-3 h-8" />
      <div class="w-full">
        <ChatCommentInput
          type="reply"
          :module="props.module"
          :chat_store="chat_store"
          :user="logged_in_user_id"
          :asset_id="asset_id"
          :comment="comment"
          :validate_comment="props.validate_comment"
          :comment_input_config="{
            autofocus: true,
            close_on_disable: true,
            disabled_controls: ['settings', 'attach', 'like', 'pin'],
            type: 'static',
          }"
          :attachment_config="{ meta: { service: props.module, id: props.item_uid } }"
          @send="scrollToReply"
          @close="can_reply = false"
        />
      </div>
    </div>
  </div>
</template>
